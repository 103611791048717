import React from "react"
import { PageWrapper } from "~components"
import HeaderButton from '~sections/project/HeaderWL'
//import { Link } from "gatsby";
import PrivacyPolicy from "~sections/privacy-policy/PrivacyPolicy"
import FooterWL from "~sections/marketing/FooterWL/FooterWL"
import CtaWL from "~sections/marketing/CtaWL/CtaWL"
//import scrollTo from 'gatsby-plugin-smoothscroll';
const header = {
  headerClasses: "site-header site-header--menu-start light-header site-header--sticky",
  containerFluid:false,
  buttonBlock: (
    <HeaderButton
      className="ms-auto d-none d-xs-inline-flex"
      btnOneText="Login"
      btnTwoText="Apply now"
      mr="15px"
      mrLG="0"
    />
  ),
}
const PageCmp = ({ data }) => {
  
  useEffect(() => {
      window.scrollTo(0,0)
  }, [data])
  
}
export default function Privacy() {
  return (
    <PageWrapper headerConfig={header}>
      <PrivacyPolicy />
      <CtaWL />
      <FooterWL/>
    </PageWrapper>
  )
}
